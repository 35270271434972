<template>
  <div class="new-line-input">
    <p class="new-line-input-title">{{ title }}：</p>
    <div
      class="new-line-input-input-wrap"
      :style="{ maxWidth: `${maxWidth}px` }"
    >
      <span class="new-line-input-input-wrap-span">{{ value }}</span>
      <input
        class="new-line-input-input-wrap-input"
        type="text"
        ref="input"
        v-model="value"
        :readonly="isReadOnly"
        @input="onInputInput"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    initalValue: String,
    maxWidth: Number,
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      value: "",
    };
  },

  created: function () {
    this.value = this.initalValue;
  },

  watch: {
    initalValue() {
      this.value = this.initalValue; //为了BMI的实时变化
    },
  },

  methods: {
    onInputInput: function () {
      console.log(this.value);
      this.$emit("onInput", this.title, this.value);
    },
  },
};
</script>

<style scoped lang="scss">
.new-line-input {
  position: relative;
  font-size: rem(14);
  box-sizing: border-box;
  padding-left: rem(20);
  display: flex;

  &-title {
    color: black;
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }

  &-input-wrap {
    position: relative;
    font-size: rem(14);
    flex: 1;

    &-span {
      display: inline-block;
      height: 100%;
      background: inherit;
      white-space: nowrap;
      opacity: 0;
      box-sizing: border-box;
      padding: 4px 6px;
      min-height: 19px;
    }

    &-input {
      border-bottom: 1px solid black;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 4px 6px;
    }
  }
}
</style>

<template>
  <div>
    <!-- REPORT_TEMPLATE.广东省人民医院珠海医院呼吸科 -->
    <div
      v-if="model.reportTemplateType == 14"
      class="title"
      style="display: block"
    >
      <div class="title-content-pic">
        <img class="title-content-pic-pic" :src="model.hospitalIcon" />
        <p class="title-content-pic-subtitle">{{ model.reportSubtitle }}</p>
      </div>
      <div class="title-buttons">
        <button
          class="title-buttons-item noPrint"
          data-html2canvas-ignore="true"
          @click="onShareClick"
        >
          分享
        </button>
        <button
          class="title-buttons-item noPrint"
          data-html2canvas-ignore="true"
          @click="onDownloadPDFClick"
        >
          下载PDF
        </button>
        <button
          class="title-buttons-item noPrint"
          data-html2canvas-ignore="true"
          @click="onDownloadPNGClick"
        >
          下载PNG
        </button>
        <button
          class="title-buttons-item right noPrint"
          data-html2canvas-ignore="true"
          @click="onPrintClick"
        >
          打印
        </button>
      </div>
    </div>
    <div v-else class="title">
      <div class="title-content-normal">
        <div class="title-content-normal-left">
          <img
            class="title-content-normal-left-icon"
            :src="model.hospitalIcon"
            alt=""
          />
        </div>
        <div class="title-content-normal-middle">
          <p
            class="title-content-normal-middle-title"
            v-for="(item, index) in model.reportTitleArray"
            :key="index"
          >
            {{ item }}
          </p>
          <p
            :class="[
              model.reportTitleArray
                ? 'title-content-normal-middle-subtitle'
                : 'title-content-normal-middle-title',
            ]"
          >
            {{ model.reportSubtitle }}
          </p>
          <!-- REPORT_TEMPLATE.上海市第一人民医院酒泉医院 -->
          <p
            v-if="model.reportTemplateType == 17"
            class="title-content-normal-middle-subtitle-2"
          >
            睡眠呼吸报告
          </p>
        </div>
      </div>
      <div class="title-buttons">
        <button
          class="title-buttons-item noPrint"
          data-html2canvas-ignore="true"
          @click="onShareClick"
        >
          分享
        </button>
        <button
          class="title-buttons-item noPrint"
          data-html2canvas-ignore="true"
          @click="onDownloadPDFClick"
        >
          下载PDF
        </button>
        <button
          class="title-buttons-item noPrint"
          data-html2canvas-ignore="true"
          @click="onDownloadPNGClick"
        >
          下载PNG
        </button>
        <button
          class="title-buttons-item right noPrint"
          data-html2canvas-ignore="true"
          @click="onPrintClick"
        >
          打印
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: Object,
  },

  data() {
    return {
      icon: require("@imgs/main-weak-password-alert.png"),
    };
  },

  methods: {
    onDownloadPDFClick() {
      this.$emit("onDownloadPDFClick");
    },
    onDownloadPNGClick() {
      this.$emit("onDownloadPNGClick");
    },
    onPrintClick() {
      this.$emit("onPrintClick");
    },
    onShareClick() {
      this.$emit("onShareClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  height: rem(120);
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: rem(10);

  &-content-pic {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 110px;
    justify-content: center;

    &-pic {
      height: 35px;
      margin-bottom: 10px;
    }

    &-subtitle {
      font-size: rem(18);
      line-height: rem(24);
    }
  }

  &-content-normal {
    width: 100%;

    &-left {
      width: rem(135);
      position: absolute;
      left: 0;

      &-icon {
        height: rem(70);
      }
    }

    &-middle {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      &-title {
        font-size: rem(24);
        font-weight: bold;
        line-height: rem(32);
      }

      &-subtitle {
        font-size: rem(18);
        line-height: rem(32);
      }

      &-subtitle-2 {
        font-size: rem(16);
        line-height: rem(24);
      }
    }
  }

  &-buttons {
    align-self: flex-end;
    display: flex;
    position: absolute;
    right: 0;

    &-item {
      color: white;
      background-color: #169bd5;
      font-size: rem(12);
      border-radius: rem(4);
      width: rem(58);
      height: rem(25);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: rem(8);
    }
  }
}

@media print {
  .noPrint {
    display: none;
    background-color: white !important;
  }
}
</style>

<template>
  <div class="user-info">
    <div class="user-info-top">
      <div class="user-info-top-left" :class="userClasses">
        <line-input
          v-if="showReporter"
          title="报告"
          :initalValue="model.initialOperator"
          @onInput="onInputInput"
        ></line-input>
        <line-input
          v-if="showReviewer"
          :title="reviewerTitle"
          :initalValue="model.initialReviewer"
          @onInput="onInputInput"
        ></line-input>
        <button
          class="user-info-top-left-button noPrint"
          data-html2canvas-ignore="true"
          @click="onSaveClick"
        >
          保存
        </button>
      </div>
      <div style="flex: 1"></div>
      <p v-if="showCreateDate" class="user-info-top-right">
        {{ model.createDate }}
      </p>
    </div>
    <!-- 广东省人民医院珠海医院呼吸科 -->
    <div v-if="model.reportTemplateType == 14">
      <div class="user-info-bottom">
        <new-line-input
          title="姓名"
          :initalValue="model.initialPatientName"
          :maxWidth="210"
          @onInput="onInputInput"
        ></new-line-input>
        <line-input
          title="年龄"
          :initalValue="model.initialPatientAge"
          @onInput="onInputInput"
        ></line-input>
        <line-input
          title="性别"
          :initalValue="model.initialPatientGender"
          @onInput="onInputInput"
        ></line-input>
      </div>
      <div class="user-info-bottom">
        <line-input
          title="病案号"
          :initalValue="model.initialPatientDiagnoseNumber"
          @onInput="onInputInput"
        ></line-input>
        <line-input
          title="床位号"
          :initalValue="model.initialPatientBedNumber"
          @onInput="onInputInput"
        ></line-input>
        <line-input
          title="科室"
          :initalValue="model.initialPatientDepartment"
          @onInput="onInputInput"
        ></line-input>
      </div>
      <div class="user-info-bottom">
        <line-input
          title="身高(cm)"
          :initalValue="model.initialPatientHeight"
          @onInput="onInputInput"
        ></line-input>
        <line-input
          title="体重(Kg)"
          :initalValue="model.initialPatientWeight"
          @onInput="onInputInput"
        ></line-input>
        <line-input
          title="BMI"
          :initalValue="patientBMI"
          :isReadOnly="true"
          @onInput="onInputInput"
        ></line-input>
      </div>
    </div>
    <!-- 台州恩泽医疗中心集团恩泽医院 -->
    <div v-else-if="model.reportTemplateType == 3">
      <div class="user-info-bottom">
        <line-input
          title="姓名"
          :initalValue="model.initialPatientName"
          @onInput="onInputInput"
        ></line-input>
        <line-input
          title="身高(cm)"
          :initalValue="model.initialPatientHeight"
          @onInput="onInputInput"
        ></line-input>
        <line-input
          title="年龄"
          class="user-info-bottom-min"
          :initalValue="model.initialPatientAge"
          @onInput="onInputInput"
        ></line-input>
        <line-input
          title="病案号"
          class="user-info-bottom-max"
          :initalValue="model.initialPatientDiagnoseNumber"
          @onInput="onInputInput"
        ></line-input>
      </div>
      <div class="user-info-bottom">
        <line-input
          title="性别"
          :initalValue="model.initialPatientGender"
          @onInput="onInputInput"
        ></line-input>
        <line-input
          title="体重(Kg)"
          :initalValue="model.initialPatientWeight"
          @onInput="onInputInput"
        ></line-input>
        <line-input
          title="BMI"
          class="user-info-bottom-min"
          :initalValue="patientBMI"
          :isReadOnly="true"
          @onInput="onInputInput"
        ></line-input>
        <line-input
          title="科室"
          class="user-info-bottom-max"
          :initalValue="model.initialPatientDepartment"
          @onInput="onInputInput"
        ></line-input>
      </div>
    </div>
    <div v-else>
      <div class="user-info-bottom">
        <new-line-input
          title="姓名"
          :initalValue="model.initialPatientName"
          :maxWidth="425"
          @onInput="onInputInput"
        ></new-line-input>
        <line-input
          title="性别"
          :initalValue="model.initialPatientGender"
          @onInput="onInputInput"
        ></line-input>
      </div>
      <div class="user-info-bottom">
        <line-input
          title="年龄"
          :initalValue="model.initialPatientAge"
          @onInput="onInputInput"
        ></line-input>
        <line-input
          :title="mzhTitle"
          :initalValue="model.initialPatientDiagnoseNumber"
          @onInput="onInputInput"
        ></line-input>
        <!-- 江门市人民医院耳鼻喉科 -->
        <line-input
          v-if="model.reportTemplateType == 10"
          title="手机号"
          :initalValue="model.initInitialMobile"
          @onInput="onInputInput"
        ></line-input>
        <line-input
          v-else
          title="科室"
          :initalValue="model.initialPatientDepartment"
          @onInput="onInputInput"
        ></line-input>
      </div>
      <div class="user-info-bottom">
        <line-input
          title="身高(cm)"
          :initalValue="model.initialPatientHeight"
          @onInput="onInputInput"
        ></line-input>
        <line-input
          title="体重(Kg)"
          :initalValue="model.initialPatientWeight"
          @onInput="onInputInput"
        ></line-input>
        <line-input
          title="BMI"
          :initalValue="patientBMI"
          :isReadOnly="true"
          @onInput="onInputInput"
        ></line-input>
      </div>
    </div>
  </div>
</template>

<script>
import LineInput from "./line-input.vue";
import NewLineInput from "./new-line-input.vue";
export default {
  components: {
    LineInput,
    NewLineInput,
  },

  props: {
    patientBMI: String,
    model: Object,
  },
  computed: {
    showReporter() {
      switch (this.model.reportTemplateType) {
        case REPORT_TEMPLATE.台州恩泽医疗中心集团恩泽医院:
        case REPORT_TEMPLATE.舟山市第二人民医院:
        case REPORT_TEMPLATE.中山大学附属第三医院·河源市中医院耳鼻喉科·广州市第十二人民医院·中山大学附属第八医院耳鼻喉科:
        case REPORT_TEMPLATE.南方医科大学顺德医院耳鼻咽喉头颈外科:
        case REPORT_TEMPLATE.山东省精神卫生中心:
        case REPORT_TEMPLATE.广东省人民医院珠海医院呼吸科:
          return false;
        default:
          return true;
      }
    },

    showReviewer() {
      switch (this.model.reportTemplateType) {
        case REPORT_TEMPLATE.台州恩泽医疗中心集团恩泽医院:
        case REPORT_TEMPLATE.中山大学附属第三医院·河源市中医院耳鼻喉科·广州市第十二人民医院·中山大学附属第八医院耳鼻喉科:
        case REPORT_TEMPLATE.南方医科大学顺德医院耳鼻咽喉头颈外科:
        case REPORT_TEMPLATE.山东省精神卫生中心:
        case REPORT_TEMPLATE.广东省人民医院珠海医院呼吸科:
          return false;
        default:
          return true;
      }
    },

    showCreateDate() {
      switch (this.model.reportTemplateType) {
        case REPORT_TEMPLATE.台州恩泽医疗中心集团恩泽医院:
        case REPORT_TEMPLATE.南方医科大学顺德医院耳鼻咽喉头颈外科:
          return false;
        default:
          return true;
      }
    },

    reviewerTitle() {
      switch (this.model.reportTemplateType) {
        case REPORT_TEMPLATE.舟山市第二人民医院:
          return "医师";
        default:
          return "审核";
      }
    },

    mzhTitle() {
      switch (this.model.reportTemplateType) {
        case REPORT_TEMPLATE.山东省立医院集团鲁东医院:
          return "登记号";
        case REPORT_TEMPLATE.舟山市第二人民医院:
        case REPORT_TEMPLATE.南方医科大学顺德医院耳鼻咽喉头颈外科:
          return "住院号";
        case REPORT_TEMPLATE.南京市儿童医院:
          return "就诊卡号";
        default:
          return "病案号";
      }
    },

    userClasses() {
      switch (this.model.reportTemplateType) {
        case REPORT_TEMPLATE.台州恩泽医疗中心集团恩泽医院:
          return ["user-info-top-left-wide"];
        case REPORT_TEMPLATE.舟山市第二人民医院:
        case REPORT_TEMPLATE.山东省精神卫生中心:
          return ["user-info-top-left-extra-wide"];
        default:
          return [];
      }
    },
  },

  methods: {
    onInputInput(title, value) {
      this.$emit("onInputInput", title, value);
    },
    onSaveClick() {
      this.$emit("onSaveClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.user-info {
  margin-bottom: rem(20);
  margin-top: rem(20);

  &-top {
    height: rem(40);
    margin-top: rem(10);
    margin-bottom: rem(10);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left {
      display: flex;
      align-items: center;

      .line-input {
        width: 27%;
      }

      &-button {
        color: white;
        background-color: #16d585;
        font-size: rem(12);
        border-radius: rem(4);
        width: rem(50);
        height: rem(25);
        margin-left: rem(20);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-left-wide {
      display: flex;
      align-items: center;
      margin-left: auto;

      .line-input {
        width: 27%;
      }

      &-button {
        color: white;
        background-color: #16d585;
        font-size: rem(12);
        border-radius: rem(4);
        width: rem(50);
        height: rem(25);
        margin-left: rem(20);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-left-extra-wide {
      display: flex;
      align-items: center;

      .line-input {
        width: 50%;
      }

      &-button {
        color: white;
        background-color: #16d585;
        font-size: rem(12);
        border-radius: rem(4);
        width: rem(50);
        height: rem(25);
        margin-left: rem(20);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-right {
      color: black;
      font-size: rem(14);
      margin-right: rem(12);
    }
  }

  &-bottom {
    width: 100%;
    height: rem(40);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .line-input {
      width: 30%;
    }

    .new-line-input {
      width: 30%;
    }

    &-min {
      width: 18% !important;
    }

    &-max {
      width: 50% !important;
    }
  }
}

@media print {
  .noPrint {
    display: none;
    background-color: white !important;
  }
}
</style>
